<template>
	<div>
		<div class="container">
			<div class="form-box">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 请选择组织 -->
					<el-form-item label="选择组织" prop="choose">
						<el-input v-model="form.choose" readonly placeholder="请选择所属组织"
							@click="visible = true"></el-input>
					</el-form-item>
					<!-- 答题时间 -->
					<el-form-item label="过期时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="daterange" range-separator="至"
								start-placeholder="开始时间" end-placeholder="结束时间" @change="immediUpdate" />
						</div>
					</el-form-item>
					<!-- 说明 -->
					<el-form-item label="过期说明" prop="desc">
						<div class="shuoming">
							<el-input type="textarea" v-model="form.desc" placeholder="请填写过期说明"></el-input>
						</div>
					</el-form-item>
					<!-- 提示 -->
					<!-- <div class="remind">
            提示：本操作不可撤销，请谨慎操作
          </div> -->
					<div class="hint">
						<div class="reminds">提示：</div>
						<div>
							<div class="become"> 本操作不可撤销，请谨慎操作！</div>
						</div>
					</div>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-button class="tijiaos" type="primary" :loading="loa" @click="onSubmit">提交</el-button>
					</div>
					<!-- 组织列表弹窗 -->
					<div>
						<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']"
							@yes="yes">
							<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
						</s3-layer>
					</div>
					<!-- 提交提示弹窗 -->
					<el-dialog v-model="centre" width="25%" title="提示" center align-center>
						<div class="treasure">
							是否手动执行过期
						</div>
						<template #footer>
							<el-button @click="centre = false" size="default">关闭</el-button>
							<el-button type="primary" @click="cowry" size="default">
								确定
							</el-button>
						</template>
					</el-dialog>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted
	} from "vue";
	import {
		Zzzlist,
		GQhand
	} from '../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import moments from 'moment'
	import {
		useStore
	} from "vuex";
	export default {
		name: "JFmanual",
		setup() {
			const route = useRoute();
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 手动过期接口
			const hand = () => {
				let data = {
					startTime: a.value,
					endTime: out.value,
					unionCode: gaincode.value,
					reason: form.desc
				}
				GQhand(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						centre.value = false
						ElMessage({
							message: "任务已提交",
							type: "success",
							center: true,
						});
						setTimeout(() => {
							current.value = route.path
							expression.value = store.state.tagsList

							expression.value.forEach((item, index) => {
								if (current.value == item.path) {
									Indexs.value = index
								}
							})
							store.state.tagsList.splice(Indexs.value, 1)
							router.push({
								name: 'JFexceed',
								params: {
									userId: 1
								}
							});
							// router.push('/JFexceed')
						}, 500)
					} else {
						centre.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}
			// 提交按钮弹窗确定
			const cowry = () => {
				hand()
				// console.log('点击了')
				// centre.value=false
				// console.log('组织code', gaincode.value)
				// console.log('开始时间',a.value)
				// console.log('结束时间',out.value)
				// console.log('过期说明',form.desc)
			}

			let a = ref('')
			let b = ref('')
			let c = ref('')
			let out = ref('')

			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
					c.value = moments(b.value * 1000).format().substring(0, 10)
					out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
					c.value = ''
					out.value = ''
				}
				console.log(e)
			}



			// 提交提示弹窗
			let centre = ref(false)





			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),
			]
			onMounted(() => {
				messge()
			})
			// 获取组织列表信息
			const messge = () => {
				Zzzlist().then((res) => {
					console.log(res)
					texture.value = res.data.data.allUnionList
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const visible = ref(false);
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				choose: "",
				value1: '',
				desc: ''
			})
			// 表单提交
			const router = useRouter();
			const loa = ref(false)
			const onSubmit = () => {
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					if (valid == true) {
						centre.value = true
					}
				})
			}
			// 验证表单提示
			const rules = {
				desc: [{
					required: true,
					message: "请填写过期说明",
					trigger: "change"
				}, ],
				choose: [{
					required: true,
					message: "请选择组织",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择时间",
					trigger: "change"
				}, ]
			}
			return {
				immediUpdate,
				cowry,
				centre,
				defaultTime2,
				// 提交按钮
				loa,
				// 树形选择内容
				yes,
				visible,
				dendrogram,
				defaultProps,
				texture,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .el-overlay-dialog {
		position: fixed;
		top: 10%;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
	}

	.treasure {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
	}

	.remind {
		margin-left: 80px;
		font-size: 14px;
		margin-top: 5px;
		color: #F56C6C;
		margin-top: -10px;
		margin-bottom: 30px;
		// font-weight: bold;
	}

	::v-deep .el-date-editor.el-input__wrapper {
		width: 95%;
	}

	::v-deep .shuoming {
		width: 100%;
	}

	::v-deep .el-form-item__label {
		font-weight: bold;
	}

	::v-deep .tijiaos {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .submit {
		margin-left: 80px;
	}

	::v-deep .el-input {
		height: 44px;
	}

	::v-deep .el-form-item {
		margin-bottom: 30px;
		align-items: center;
	}

	::v-deep .el-tree-node__content {
		font-weight: 500;
	}

	::v-deep .el-select-dropdown__item.hover {
		background-color: #ffffff;
	}

	::v-deep .el-select-dropdown__wrap {
		background-color: #ffffff;
	}

	::v-deep .form-box {
		width: 500px;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .container {
		max-width: 91%;
		margin: auto;
		margin-top: 10px;
		padding: 30px;
		background: #fff;
		border: 1px solid #ddd;
		border-radius: 5px;
	}

	.el-dialog__body {
		padding: 10px 10px 10px 10px !important;
		height: 30px !important;
		overflow: auto;
	}

	.hint {
		width: max-content;
		/*  text-align: justify; */
		display: flex;
		font-size: 16px;
		align-items: center;
		justify-content: center;
		margin: 0 0 30px 0;
		/* margin-left: 75px;
		  margin-top: -10px;
		  margin-bottom: 20px; */
	}

	.reminds {
		width: 78px;
		color: red;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		text-align: right;
	}

	.become {
		color: red;
		font-size: 14px;
	}
</style>